/*
 * BASIC TABLES
 */
.table thead th {
    border-bottom: 1px solid #eceff4;
    outline: none !important;
}

.table thead th:focus {
    background: #f2f4f8;
}

.table td, .table th {
    border-color: #eceff4;
}

.table td:focus, .table th:focus {
   outline: none;
}

.table tbody tr td {
    border-top: none;
    padding-top: 15px;
    padding-left: 0px;
    font-size: 14px;
    border-bottom: 1px solid #e3e3e3;
    font-weight: 200;
    width: 1%;
}

.table.table-hover tbody tr:hover {
    /*background: #f2f4f8;*/
}

.table tr.active, .table th.active {
    background: #f2f4f8;
}

.table .thead-inverse th {
    background: #38354a;
}

.table .thead-default th {
    background: #eceff4;
}

.table.table-striped tbody tr:nth-of-type(odd) {
    background: #f2f4f8;
}

.table-inverse thead th {
    border-bottom-color: #514d6a;
}

.table-inverse th, .table-inverse td {
    border-top-color: #514d6a;
}

.table-inverse th, .table-inverse td, .table-inverse thead th {
    background: #38354a;
}

.table-inverse th:focus, .table-inverse td:focus, .table-inverse thead th:focus {
    background: #2b2838;
}

.table-inverse tr.active, .table-inverse th.active {
    background: #f2f4f8;
}
