/*
 * RESPONSIVE STYLES
 */
 @media (max-width:1020px) {
  nav.top-menu {
    left: 0px !important;
  }

    nav.top-menu .menu {
      padding: 21px 15px;
    }

    nav.top-menu .menu-icon-container + .menu {
      margin-left: 65px;
    }

  nav.top-submenu, .top-submenu {
    padding: 10px 10px 0px 10px;
  }

    nav.top-submenu.top-submenu-with-background, .top-submenu.top-submenu-with-background {
      margin: -15px -15px 15px -15px;
    }

  nav.left-menu {
    -webkit-transform: translateX(-240px);
    -ms-transform: translateX(-240px);
    -o-transform: translateX(-240px);
    transform: translateX(-240px);
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: -o-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out, -o-transform 0.2s ease-in-out;
  }

    nav.left-menu + .main-container {
      margin-left: 0px;
    }

  .menu-static nav.top-menu {
    margin-left: 0px !important;
    z-index: 40;
    position: relative;
  }

  .page-content {
    padding: 15px !important;
    margin-left: 0px !important;
  }
  .panel {
    margin-bottom: 15px;
  }

    .panel.panel-with-sidebar:before {
      display: none;
    }

    .panel.panel-with-sidebar .panel-heading, .panel.panel-with-sidebar .panel-body, .panel.panel-with-sidebar .panel-footer {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .panel.panel-with-sidebar .panel-sidebar {
      float: none !important;
      border-right: none !important;
      border-left: none !important;
      width: auto;
      border-bottom: 1px solid #dfe4ed;
      margin-bottom: 0px !important;
    }

  body.menu-sliding nav.top-menu {
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: -o-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out, -o-transform 0.2s ease-in-out;
  }

  body.menu-sliding .page-content {
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: -o-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out, -o-transform 0.2s ease-in-out;
  }

  body.menu-sliding nav.left-menu.left-menu-showed + * + .page-content,
  body.menu-sliding nav.left-menu.left-menu-showed + nav.top-menu {
    -webkit-transform: translateX(240px);
    -ms-transform: translateX(240px);
    -o-transform: translateX(240px);
    transform: translateX(240px);
  }

    body.menu-sliding nav.left-menu.left-menu-showed + * + .page-content + .main-backdrop {
      display: none !important;
    }
}

@media (max-width: 991px) {
  .sidebar-large.panel {
    margin-bottom: 15px;
  }

    .sidebar-large.panel.panel-with-sidebar:before {
      display: none;
    }

    .sidebar-large.panel.panel-with-sidebar .panel-heading, .sidebar-large.panel.panel-with-sidebar .panel-body, .sidebar-large.panel.panel-with-sidebar .panel-footer {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .sidebar-large.panel.panel-with-sidebar .panel-sidebar {
      float: none !important;
      width: auto;
      border-bottom: 1px solid #dfe4ed;
      margin-bottom: 0px !important;
    }
}

@media (max-width: 543px) {
  nav.top-submenu.top-submenu-with-background, .top-submenu.top-submenu-with-background {
    margin: -15px -.9375rem .9375rem -.9375rem;
  }

  nav.top-menu .menu {
    padding-left: 0px;
  }

  .page-content {
    padding: .9375rem;
  }

  .panel {
    margin-bottom: .9375rem;
  }

    .panel .panel {
      margin-bottom: 20px;
    }

    .panel .panel-sidebar {
      padding: 20px !important;
    }

    .panel .panel-heading {
      padding: 20px;
    }

    .panel .panel-body {
      padding: 20px;
    }

    .panel .panel-footer {
      padding: 20px;
    }

    .panel.panel-with-borders > .panel-body {
      padding-top: 20px;
    }

    .panel.panel-with-borders > .panel-body {
      padding-top: 20px;
    }

  .mode-superclean .top-submenu, .mode-superclean .top-submenu.top-submenu-with-background {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 900px) {

  /* Header */
      nav.top-menu .menu-icon-container {
        margin-left: 15px;
        margin-bottom: 25px;
        margin-top: 25px;
      }
      .nav-item.last{display: none;}

      .dash-text {
        display: none;
      }

      .switch.switch-small {
        min-width: 40px !important;
        margin-left: 10px;
      }

      .dataTables_wrapper .row:last-child{
        padding: 0;
      }
      .pagination > li > a, .pagination > li > span{
        margin:0 2px 5px;
        font-size: 11px;
        padding: 4px 10px;
      }
      table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before{
        top: 17px;
        line-height: 17px;
        display: inline-block;
        margin-right: 10px;
      }
      .table tbody tr td.sorting_1:first-child {
        vertical-align: middle;
    }
      ul.book-last-grp li {
        width: auto !important;
        padding-right: 10px;
        padding-top: 10px !important;
        padding-bottom: 0 !important;
        float: none !important;
        display: table-cell;
        text-align: center;
      }
      table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting{
        padding-right: 30px;
      }

      div.dataTables_wrapper div.dataTables_filter input{
        margin-top: 10px;
        margin-right: 10px;
      }

      .dataTables_length {
        margin-top: 10px;
    }  
    tr.ng-star-inserted td{
      padding-right: 10px;
    }

    table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before{
      line-height: 17px;
    }

    .dataTables_wrapper .row:last-child .col-sm-5{float: none !important;}
    
}


@media only screen and (min-width: 320px) and (max-width: 334px) {

  /* list-screen */
  .nav-link {
    padding-top: 2px !important;
  }

  li.nav-item {
    padding-left: 0px;
    padding-right: 20px;
    margin-top: -5px;
  }

  .inner-menu ul li {
    padding: 8px;
    font-size: 12px;
  }

  .menu-right:before {
    margin-left: -7px;
    margin-top: 8px;
  }

  .frame {
    margin-left: 5px;
  }

  .upper-list-content p {
    font-size: 10px !important;
  }

  .main-sec {
    margin-left: 0px;
  }

  .resize {
    margin-top: 0px;
  }

  .loc-text {
    font-size: 14px;
  }

  .add-btn {
    padding: 10px 10px;
    font-size: 12px;
  }

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .round {
    width: 25px !important;
    height: 25px !important;
    font-size: 10px !important;
    margin-right: 2px !important;
  }

  .pagging {
    float: none;
    padding-left: 0px;
    text-align: center;
  }

  .pagging-text {
    float: none;
    padding: 0px;
    text-align: center;
  }

  .form-content-drop {
    font-size: 13px;
    padding: 3px 10px;
  }

  .pages span {
    font-size: 10px;
  }

  .footer-panel span {
    margin-left: 0px;
  }

  .footer-panel {
    height: auto;
  }

  .sorting {
    padding-right: 30px !important;
  }

  .table tbody tr td {
    padding-right: 0px;
    font-size: 10px;
    border-bottom: none;
  }

  .space {
    margin-left: 3px;
  }

  /* add-screen */
  .inner-menu span {
    font-size: 14px;
  }

  .inner-menu p {
    font-size: 16px;
    margin-top: 5px;
  }

  .main-sec {
    font-size: 16px;
  }

  .panel .panel-heading + .panel-body {
    padding-top: 0px;
  }

  .btn-form {
    text-align: center;
  }

  .go-btn {
    padding: 10px 30px;
  }



  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }

  .booking {
    margin-left: 0;
  }

  .book1 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

    .book2 span {
      font-size: 10px;
    }

  .brok-img {
    /* width: 22px;
    height: 22px; */
    bottom: 20px;
    right: 1px;
  }

  .img-resize {
    width: 15px;
    height: 15px;
  }

  .cols-section {
    width: 600px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp {
    float: left;
  }

    ul.book-last-grp li {
      width: auto;
      padding-right: 9px;
    }


  /* view-location */
  .upper-head-content h5 {
    font-size: 16px;
  }

  .upper-head-content p {
    font-size: 12px;
  }

  .info-blog-left {
    text-align: left;
    float: none;
    margin: 0px;
  }

  .info-blog-right {
    text-align: left;
    float: none;
  }

  .right-side {
    text-align: left;
  }

  .cinema-inner-menu {
    font-size: 16px;
  }

  ul.cinema-elements li {
    width: 100%;
    float: none;
    padding: 5px 0px 20px 0px;
  }

  /* list-movie */
  .upload-block {
    width: 100%;
  }

  .upload-section {
    padding-left: 0px !important;
  }

  .odd {
    border-bottom: 1px solid #ddd;
  }

  .table tbody tr:first-child td {
    width: auto;
    padding-right: 0;
    
  }

  .table-res {
    display: none;
  }

  .table-res1 {
    display: none;
  }

  .table-res2 {
    display: none;
  }

  .sorting {
    font-size: 12px !important;
  }

  .sorting_disabled {
    font-size: 12px !important;
  }

  a.sorting_1 {
    display: block;
    position: relative;
    margin-left: -20px;
  }

    a.sorting_1:before {
      height: 14px;
      width: 14px;
      display: block;
      position: absolute;
      color: white;
      border: none;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      text-align: center;
      line-height: 15px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      content: '+';
      background-color: #2c77f4;
      /*cursor: pointer;*/
    }

  .table .child-new tbody tr td {
    font-size: 12px !important;
  }

  .child-new table {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: 60px;
  }

  .mv {
    padding-left: 50px !important;
  }

  .sc {
    padding-left: 85px !important;
  }
}

@media only screen and (min-width: 335px) and (max-width: 374px) {

  /* list-screen */
  li.nav-item {
    padding-left: 0px;
    padding-right: 20px;
    margin-top: -5px;
  }

  .inner-menu ul li {
    padding: 8px;
    font-size: 14px;
  }

  .menu-right:before {
    margin-left: -7px;
    margin-top: 7px;
  }

  .frame {
    margin-left: 5px;
  }

  .upper-list-content p {
    font-size: 10px;
  }

  .main-sec {
    margin-left: 0px;
  }

  .resize {
    margin-top: 0px;
  }

  .loc-text {
    font-size: 14px;
  }

  .add-btn {
    padding: 10px 15px;
    font-size: 12px;
  }

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .round {
    width: 25px !important;
    height: 25px !important;
    font-size: 10px !important;
    margin-right: 2px !important;
  }

  .pagging {
    float: none;
    padding-left: 0px;
    text-align: center;
  }

  .pagging-text {
    float: none;
    padding: 0px;
    text-align: center;
  }

  .form-content-drop {
    font-size: 13px;
    padding: 3px 10px;
  }

  .pages span {
    font-size: 10px;
  }

  .footer-panel span {
    margin-left: 0px;
  }

  .footer-panel {
    height: auto;
  }

  .sorting {
    padding-right: 30px !important;
  }

  .table tbody tr:first-child td {
    padding-right: 0px;
    font-size: 12px;
  }

  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }
  .book1 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

    .book2 span {
      font-size: 10px;
    }

  .brok-img {
    width: 22px;
    height: 22px;
    bottom: 20px;
    right: 1px;
  }

  .img-resize {
    width: 15px;
    height: 15px;
  }

  .cols-section {
    width: 600px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp {
    float: left;
  }

    ul.book-last-grp li {
      width: auto;
      padding-right: 9px;
    }



  /* view-location */
  .upper-head-content h5 {
    font-size: 16px;
  }

  .upper-head-content p {
    font-size: 12px;
  }

  .info-blog-left {
    text-align: left;
    float: none;
    margin: 0px;
  }

  .info-blog-right {
    text-align: left;
    float: none;
  }

  .right-side {
    text-align: left;
  }

  .cinema-inner-menu {
    font-size: 16px;
  }

  ul.cinema-elements li {
    width: 100%;
    float: none;
    padding: 5px 0px 20px 0px;
  }

  /* list-movie */
  .upload-block {
    width: 100%;
  }

  .upload-section {
    padding-left: 0px !important;
  }

  .space {
    margin-left: 3px;
  }

  .odd {
    border-bottom: 1px solid #ddd;
  }

  .table tbody tr:first-child td {
    width: auto;
  }

  .table-res {
    display: none;
  }

  .table-res1 {
    display: none;
  }

  .table-res2 {
    display: none;
  }

  a.sorting_1 {
    display: block;
    position: relative;
    margin-left: -20px;
    bottom: -2px;
  }

    a.sorting_1:before {
      height: 14px;
      width: 14px;
      display: block;
      position: absolute;
      color: white;
      border: none;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      text-align: center;
      line-height: 15px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      content: '+';
      background-color: #2c77f4;
      /*cursor: pointer;*/
    }

  .table .child-new tbody tr td {
    font-size: 12px !important;
  }

  .child-new table {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: 60px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 405px) {
  /* list-screen */
  li.nav-item {
    padding-left: 0px;
    padding-right: 20px;
    margin-top: -5px;
  }

  .inner-menu ul li {
    padding: 8px;
    font-size: 14px;
  }

  .menu-right:before {
    margin-left: -7px;
    margin-top: 9px;
  }

  .frame {
    margin-left: 5px;
  }

  .main-sec {
    margin-left: 0px;
  }

  .resize {
    margin-top: 0px;
  }

  .loc-text {
    font-size: 14px;
  }

  .add-btn {
    padding: 10px 15px;
    font-size: 12px;
  }

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .round {
    width: 30px !important;
    height: 30px !important;
    font-size: 12px !important;
    margin-right: 5px !important;
  }

  .pagging {
    float: none;
    padding-left: 0px;
    text-align: center;
  }

  .pagging-text {
    float: none;
    padding: 0px;
    text-align: center;
  }

  .footer-panel span {
    margin-left: 0px;
  }

  .footer-panel {
    height: auto;
  }

  .table tbody tr:first-child td {
    padding-right: 0px;
    padding-bottom: 17px;
  }

  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }

  .booking {
    margin-left: 0;
  }

  .book1 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  ul.book-col2 li {
    width: auto;
  }
  .book2 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px !important;
    height: 20px !important;
}

    .book2 span {
      font-size: 10px;
    }

  .brok-img {
    /* width: 22px;
    height: 22px; */
    bottom: 20px;
    right: 1px;
  }

  .img-resize {
    width: 15px;
    height: 15px;
  }

  .cols-section {
    width: 600px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp {
    float: left;
  }

    

  /* view-location */
  .upper-head-content h5 {
    font-size: 16px;
  }

  .upper-head-content p {
    font-size: 12px;
  }

  .info-blog-left {
    text-align: left;
    float: none;
    margin: 0px;
  }

  .info-blog-right {
    text-align: left;
    float: none;
  }

  .right-side {
    text-align: left;
  }

  .cinema-inner-menu {
    font-size: 16px;
  }

  ul.cinema-elements li {
    width: 100%;
    float: none;
    padding: 5px 0px 20px 0px;
  }

  /* list-movie */
  .upload-section {
    padding-left: 0px !important;
  }

  .space {
    margin-left: 3px;
  }

  .odd {
    border-bottom: 1px solid #ddd;
  }

  .table tbody tr:first-child td {
    width: inherit !important;
  }

  .table-res {
    display: none;
  }

  .table-res1 {
    display: none;
  }

  .table-res2 {
    display: none;
  }

  a.sorting_1 {
    display: block;
    position: relative;
    margin-left: -20px;
    bottom: -3px;
  }

    a.sorting_1:before {
      height: 14px;
      width: 14px;
      display: block;
      position: absolute;
      color: white;
      border: none;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      text-align: center;
      line-height: 15px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      content: '+';
      background-color: #2c77f4;
      /*cursor: pointer;*/
    }

  .table .child-new tbody tr td {
    font-size: 12px !important;
  }

  .child-new table {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: 60px;
  }
}

@media only screen and (min-width: 406px) and (max-width: 425px) {
  /* list-screen */
  li.nav-item {
    padding-left: 0px;
    padding-right: 20px;
    margin-top: -5px;
  }

  .inner-menu ul li {
    padding: 8px;
    font-size: 16px;
  }

  .menu-right:before {
    margin-left: -7px;
    margin-top: 9px;
  }

  .frame {
    margin-left: 5px;
  }

  .main-sec {
    margin-left: 0px;
  }

  .resize {
    margin-top: 0px;
  }

  .loc-text {
    font-size: 14px;
  }

  .add-btn {
    padding: 10px 15px;
    font-size: 12px;
  }

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .round {
    width: 30px !important;
    height: 30px !important;
    font-size: 12px !important;
    margin-right: 5px !important;
  }

  .pagging {
    float: none;
    padding-left: 0px;
    text-align: center;
  }

  .pagging-text {
    float: none;
    padding: 0px;
    text-align: center;
  }

  .footer-panel span {
    margin-left: 0px;
  }

  .footer-panel {
    height: auto;
  }

  .table tbody tr:first-child td:first-child  {
    padding-right: 30px;
    padding-bottom: 17px;
  }

  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }

  .booking {
    margin-left: 0;
  }

  .book1 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

    .book2 span {
      font-size: 10px;
    }

  .brok-img {
    width: 22px;
    height: 22px;
    bottom: 20px;
    right: 1px;
  }

  .img-resize {
    width: 15px;
    height: 15px;
  }

  .cols-section {
    width: 600px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp {
    float: left;
  }

    ul.book-last-grp li {
      width: auto;
      padding-right: 9px;
    }


  /* view-location */
  .upper-head-content h5 {
    font-size: 16px;
  }

  .upper-head-content p {
    font-size: 12px;
  }

  .info-blog-left {
    text-align: left;
    float: none;
    margin: 0px;
  }

  .info-blog-right {
    text-align: left;
    float: none;
  }

  .right-side {
    text-align: left;
  }

  ul.cinema-elements li {
    width: 50%;
  }

  .e-img {
    height: 51px;
  }

  .cinema-part1 {
    text-align: center;
  }

  .cinema-part2 {
    text-align: center;
  }

  .cinema-part3 {
    text-align: center;
  }

  .cinema-part4 {
    text-align: center;
  }

  .cinema-part-blog {
    text-align: center;
    float: none;
  }

  .cinema-part-content {
    text-align: center;
    float: none;
    margin-top: 5px;
  }

    .cinema-part-content h1 {
      font-size: 30px;
    }

    .cinema-part-content span {
      font-size: 14px;
    }

  /* list-movie */
  .upload-section {
    padding-left: 0px !important;
  }

  .space {
    margin-left: 3px;
  }

  .odd {
    border-bottom: 1px solid #ddd;
  }

  .table tbody tr:first-child td {
    width: auto;
  }

  .table-res {
    display: none;
  }

  .table-res1 {
    display: none;
  }

  .table-res2 {
    display: block;
  }

  a.sorting_1 {
    display: block;
    position: relative;
    margin-left: -20px;
    bottom: -3px;
  }

    a.sorting_1:before {
      height: 14px;
      width: 14px;
      display: block;
      position: absolute;
      color: white;
      border: none;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      text-align: center;
      line-height: 15px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      content: '+';
      background-color: #2c77f4;
      /*cursor: pointer;*/
    }

  .table .child-new tbody tr td {
    font-size: 12px !important;
  }

  .child-new table {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: 60px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 445px) {
  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .sorting {
    padding-right: 30px !important;
  }

  .table tbody tr:first-child td {
    padding-right: 0px;
    padding-bottom: 17px;
  }

  .pagging {
    float: none;
    padding-left: 0px;
    text-align: center;
  }

  .pagging-text {
    float: none;
    padding: 0px;
    text-align: center;
  }

  .footer-panel {
    height: auto;
  }

    .footer-panel span {
      margin-left: 0px;
    }

  .info-blog-left {
    float: none;
    margin: 0px;
  }

  .right-side {
    float: none;
  }

  ul.cinema-elements li {
    width: 50%;
  }

  .e-img {
    height: 51px;
  }

  .cinema-part-content h1 {
    font-size: 30px;
  }

  .cinema-part-content span {
    font-size: 14px;
  }


  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }
  .book1 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

    .book2 span {
      font-size: 10px;
    }

  .brok-img {
    width: 22px;
    height: 22px;
    bottom: 20px;
    right: 1px;
  }

  .img-resize {
    width: 15px;
    height: 15px;
  }

  .cols-section {
    width: 600px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp {
    float: left;
  }

    ul.book-last-grp li {
      width: auto;
      padding-right: 9px;
    }


  /* list-movie */
  .upload-section {
    padding-left: 0px !important;
  }

  .space {
    margin-left: 3px;
  }

  .odd {
    border-bottom: 1px solid #ddd;
  }

  .table tbody tr:first-child td {
    width: auto;
  }

  .table-res {
    display: none;
  }

  .table-res1 {
    display: none;
  }

  .table-res2 {
    display: block;
  }

  a.sorting_1 {
    display: block;
    position: relative;
    margin-left: -24px;
    bottom: -3px;
  }

    a.sorting_1:before {
      top: 0px;
      left: 5px;
      height: 14px;
      width: 14px;
      display: block;
      position: absolute;
      color: white;
      border: none;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      text-align: center;
      line-height: 15px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      content: '+';
      background-color: #2c77f4;
      /*cursor: pointer;*/
    }

  .table .child-new tbody tr td {
    font-size: 12px !important;
  }

  .child-new table {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: 60px;
  }
}

@media only screen and (min-width: 446px) and (max-width:460px) {

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .sorting {
    padding-right: 30px !important;
  }

  .table tbody tr:first-child td {
    padding-right: 0px;
    padding-bottom: 17px;
  }

  .pagging {
    float: none;
    padding-left: 0px;
    text-align: center;
  }

  .pagging-text {
    float: none;
    padding: 0px;
    text-align: center;
  }

  .footer-panel {
    height: auto;
  }

    .footer-panel span {
      margin-left: 0px;
    }

  .info-blog-left {
    float: none;
    margin: 0px;
  }

  .right-side {
    float: none;
  }

  ul.cinema-elements li {
    width: 50%;
  }

  .e-img {
    height: 51px;
  }

  .cinema-part-content h1 {
    font-size: 30px;
  }

  .cinema-part-content span {
    font-size: 14px;
  }


  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }
  .book1 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

    .book2 span {
      font-size: 10px;
    }

  .brok-img {
    width: 22px;
    height: 22px;
    bottom: 20px;
    right: 1px;
  }

  .img-resize {
    width: 15px;
    height: 15px;
  }

  .cols-section {
    width: 600px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp {
    float: left;
  }

    ul.book-last-grp li {
      width: auto;
      padding-right: 9px;
    }


  /* list-movie */
  .upload-section {
    padding-left: 0px !important;
  }

  .space {
    margin-left: 3px;
  }

  .odd {
    border-bottom: 1px solid #ddd;
  }

  .table tbody tr:first-child td {
    width: auto;
  }

  .table-res {
    display: none;
  }

  .table-res1 {
    display: none;
  }

  a.sorting_1 {
    display: block;
    position: relative;
    margin-left: -24px;
    bottom: -3px;
  }

    a.sorting_1:before {
      top: 0px;
      left: 5px;
      height: 14px;
      width: 14px;
      display: block;
      position: absolute;
      color: white;
      border: none;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      text-align: center;
      line-height: 15px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      content: '+';
      background-color: #2c77f4;
      /*cursor: pointer;*/
    }

  .table .child-new tbody tr td {
    font-size: 12px !important;
  }

  .child-new table {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: 60px;
  }
}

@media only screen and (min-width: 461px) and (max-width:490px) {
  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }

  .book1 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

    .book2 span {
      font-size: 10px;
    }

  .brok-img {
    width: 22px;
    height: 22px;
    bottom: 20px;
    right: 1px;
  }

  .img-resize {
    width: 15px;
    height: 15px;
  }

  .cols-section {
    width: 600px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp {
    float: left;
  }

    ul.book-last-grp li {
      width: auto;
      padding-right: 9px;
    }

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .sorting {
    padding-right: 30px !important;
  }

  .table tbody tr:first-child td {
    padding-right: 0px;
    padding-bottom: 17px;
  }

  .pagging {
    float: none;
    padding-left: 0px;
    text-align: center;
  }

  .pagging-text {
    float: none;
    padding: 0px;
    text-align: center;
  }

  .footer-panel {
    height: auto;
  }
  .footer-panel span {
      margin-left: 0px;
  }
  .info-blog-left {
    float: none;
    margin: 0px;
  }
  .right-side {
    float: none;
  }

  ul.cinema-elements li {
    width: 50%;
  }

  .e-img {
    height: 51px;
  }

  .cinema-part-content h1 {
    font-size: 30px;
  }

  .cinema-part-content span {
    font-size: 14px;
  }

  /* list-movie */
  .upload-section {
    padding-left: 0px !important;
  }

  .space {
    margin-left: 3px;
  }

  .odd {
    border-bottom: 1px solid #ddd;
  }

  .table tbody tr:first-child td {
    width: auto;
  }

  .table-res {
    display: none;
  }

  .table-res1 {
    display: none;
  }

  .table-res2 {
    display: block;
  }

  a.sorting_1 {
    display: block;
    position: relative;
    margin-left: -24px;
    bottom: -3px;
  }

    a.sorting_1:before {
      top: 0px;
      left: 5px;
      height: 14px;
      width: 14px;
      display: block;
      position: absolute;
      color: white;
      border: none;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      text-align: center;
      line-height: 15px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      content: '+';
      background-color: #2c77f4;
      /*cursor: pointer;*/
    }

  .table .child-new tbody tr td {
    font-size: 12px !important;
  }

  .child-new table {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: 60px;
  }
}

@media only screen and (min-width: 491px) and (max-width:660px) {
  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }
  .book1 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

    .book2 span {
      font-size: 10px;
    }

  .brok-img {
    width: 22px;
    height: 22px;
    bottom: 20px;
    right: 1px;
  }

  .img-resize {
    width: 15px;
    height: 15px;
  }

  .cols-section {
    width: 600px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp {
    float: left;
  }

    ul.book-last-grp li {
      width: auto;
      padding-right: 9px;
    }

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .sorting {
    padding-right: 30px !important;
  }

  .table tbody tr:first-child td {
    padding-right: 0px;
    padding-bottom: 17px;
  }

  .pagging {
    float: none;
    padding-left: 0px;
    text-align: center;
  }

  .pagging-text {
    float: none;
    padding: 0px;
    text-align: center;
  }

  .footer-panel {
    height: auto;
  }

    .footer-panel span {
      margin-left: 0px;
    }

  .info-blog-left {
    float: none;
    margin: 0px;
  }

  .right-side {
    float: none;
  }

  ul.cinema-elements li {
    width: 50%;
  }

  .e-img {
    height: 51px;
  }

  .cinema-part-content h1 {
    font-size: 30px;
  }

  .cinema-part-content span {
    font-size: 14px;
  }

  /* list-movie */
  .upload-section {
    padding-left: 0px !important;
  }

  .space {
    margin-left: 3px;
  }

  .table tbody tr:first-child td {
    width: auto;
  }

  .table-res {
    display: none;
  }

  .table-res1 {
    display: block;
  }

  .table-res2 {
    display: block;
  }

  .show-new {
    display: none;
  }

  a.sorting_1 {
    display: block;
    position: relative;
    margin-left: -24px;
    bottom: -3px;
  }

    a.sorting_1:before {
      top: 0px;
      left: 5px;
      height: 14px;
      width: 14px;
      display: block;
      position: absolute;
      color: white;
      border: none;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      text-align: center;
      line-height: 15px;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      content: '+';
      background-color: #2c77f4;
      /*cursor: pointer;*/
    }

  .table .child-new tbody tr td {
    font-size: 14px !important;
  }

  .child-new table {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: 60px;
    width: 100%;
  }
}


@media only screen and (min-width: 661px) and (max-width: 767px) {
  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }
}

@media only screen and (min-width: 752px) and (max-width: 767px) {
  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }

  .book1 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 2px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }

    .book2 span {
      font-size: 10px;
    }

  .brok-img {
    width: 22px;
    height: 22px;
    bottom: 20px;
    right: 1px;
  }

  .img-resize {
    width: 15px;
    height: 15px;
  }

  .cols-section {
    width: 600px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp {
    float: left;
  }

    ul.book-last-grp li {
      width: auto;
      padding-right: 9px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .sorting {
    padding-right: 30px !important;
  }

  .table tbody tr:first-child td {
    padding-right: 30px;
  }

  .footer-panel {
    height: auto;
  }

  .info-blog-left {
    float: none;
    margin: 0px;
  }

  .right-side {
    float: none;
  }

  ul.cinema-elements li {
    width: 50%;
  }

  .e-img {
    height: 51px;
  }

  .cinema-part-content h1 {
    font-size: 30px;
  }

  .cinema-part-content span {
    font-size: 14px;
  }

  li.last {
    padding-right: 20px !important;
  }

  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
    overflow-x: scroll;
  }

  .cols-section {
    width: 710px;
  }
  .book1 {
    margin: 0px 0px 4px 4px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 4px;
  }

  .handi-img {
    height: 21px;
  }

  .upload-img {
    margin: 94px 35px;
  }

  .book-last {
    float: none;
  }

  ul.book-last-grp {
    float: left;
  }

    ul.book-last-grp li {
      width: auto;
      padding-right: 9px;
    }

  /* list-movie */
  .upload-section {
    padding-left: 0px !important;
  }

  .upload-block {
    width: 200px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1020px) {

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .sorting {
    padding-right: 30px !important;
  }

  .table tbody tr:first-child td {
    padding-right: 30px;
  }

  .footer-panel {
    height: auto;
  }

  .info-blog-left {
    float: none;
    margin: 0px;
  }

  .right-side {
    float: none;
  }

  ul.cinema-elements li {
    width: 50%;
  }

  .e-img {
    height: 51px;
  }

  .cinema-part-content h1 {
    font-size: 30px;
  }

  .cinema-part-content span {
    font-size: 14px;
  }

  li.last {
    padding-right: 20px !important;
  }


  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 75%;
  }
  .book1 {
    margin: 0px 0px 4px 4px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 4px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp li {
    padding-bottom: 10px;
    padding-top: 0px;
  }

  .handi-img {
    height: 21px;
  }

  ul.book-last-grp {
    padding-left: 0px;
  }
}


@media only screen and (min-width: 1021px) and (max-width: 1024px) {

  .serch-right {
    margin-right: 25px;
    width: fit-content;
  }

  .sorting {
    padding-right: 30px !important;
  }

  .table tbody tr:first-child td:first-child {
    padding-right: 30px;
  }

  .footer-panel {
    height: auto;
  }

  .info-blog-left {
    float: none;
    margin: 0px;
  }

  .right-side {
    float: none;
  }

  ul.cinema-elements li {
    width: 50%;
  }

  .e-img {
    height: 51px;
  }

  .cinema-part-content h1 {
    font-size: 30px;
  }

  .cinema-part-content span {
    font-size: 14px;
  }

  li.last {
    padding-right: 20px !important;
  }


  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 100%;
  }
  .book1 {
    margin: 0px 0px 4px 4px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 4px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp li {
    padding-bottom: 10px;
    padding-top: 0px;
  }

  .handi-img {
    height: 21px;
  }

  ul.book-last-grp {
    padding-left: 25px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1077px) {
  ul.cinema-elements li {
    width: 50%;
  }

  li.last {
    padding-right: 20px !important;
  }

  .info-blog-left {
    float: none;
    margin: 0px;
  }

  .right-side {
    float: none;
  }

  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 97%;
  }
  .book1 {
    margin: 0px 0px 4px 4px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 4px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp li {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .handi-img {
    height: 21px;
  }

  ul.book-last-grp {
    padding-left: 25px;
  }
}

@media only screen and (min-width: 1078px) and (max-width: 1310px) {
  ul.cinema-elements li {
    width: 50%;
  }

  li.last {
    padding-right: 20px !important;
  }

  .info-blog-left {
    float: none;
    margin: 0px;
  }

  .right-side {
    float: none;
  }

  /* add-screen */
  ul.book-col li {
    width: auto;
  }

  .book-block {
    width: 90%;
  }
  .book1 {
    margin: 0px 0px 4px 4px;
  }

  ul.book-col2 li {
    width: auto;
  }

  .book2 {
    margin: 0px 0px 4px 4px;
  }

  .book-last {
    float: none;
    width: auto;
  }

  ul.book-last-grp li {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .handi-img {
    height: 21px;
  }

  ul.book-last-grp {
    padding-left: 0px;
  }
}


@media only screen and (min-width: 843px) and (max-width: 885px) {
  .upload-text {
    padding: 15px 32px 10px 0px;
  }
}
@media screen and (max-width: 767px) {
    app-location-movie-schedule .panel .panel-heading {
      padding: 0;      
  }
  app-express-movie-schedule .panel .panel-heading {
    padding: 0;    
  }
  /* .dashboard_pg_dev .modal {
    z-index: 111111 !important;
  } */
  .top-btns {
    padding-right: 0 !important;
  }
  .duration p {
    margin-left: 3px;
    margin-bottom: 10px;
    font-size: 11px;
    display: inline-block;
  }
  app-location-movie-schedule .main-sec .col-md-4 {
    width: 100% !important;
    position: relative;
  }
  app-express-movie-schedule .main-sec .col-md-4 {
    width: 100% !important;
    position: relative;
  }
  app-location-movie-schedule button.add-btn1 {
    margin-bottom: 0;
    padding: 9px 15px;
    font-size: 12px;
  }
  app-express-movie-schedule button.add-btn1 {
    margin-bottom: 0;
    padding: 9px 15px;
    font-size: 12px;
  }
  app-location-movie-schedule .addbtn {
    position: absolute;
    right: 20px;
    top: -20px;
  }
  app-express-movie-schedulee .addbtn {
    position: absolute;
    right: 20px;
    top: -20px;
  }
  app-location-movie-schedule table.dataTable tbody th, app-location-movie-schedule table.dataTable tbody td {
    padding: 10px 0 !important;
  }
  app-express-movie-schedule table.dataTable tbody th, app-express-movie-schedule table.dataTable tbody td {
    padding: 10px 0 !important;
  }
  table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    margin-right: 5px;
  } 
  app-admin-layout td.child:before {
    display: none !important;
  }
  app-admin-layout table.dataTable tbody th, app-admin-layout table.dataTable tbody td {
    padding: 10px 0 !important;
  }
  span.dtr-data img {
    margin: 0 3px;
  }
  .cdk-overlay-pane {
    transform: translateY(0px) !important;
  }
  .dataTables_info {
        /* text-overflow: ellipsis; */
        width: 100%;
        /* overflow: hidden; */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        /* white-space: nowrap; */
        white-space: inherit !important;
  }
  app-inventory .table tbody tr:first-child td {
    width: 25% !important;
  }
  .panel .panel-heading {
    padding: 10px;
  }
  .btn-list-form .add-btn {
    padding: 10px 10px;
    font-size: 12px;
    width: 60% !important;
    text-align: center !important;
  }
  .btn-s2 {
    width: 100%;
  }
  .btn-m2 {
    width: 60% !important;
  }
  .checkout_inner .col-md-8.col-10 {width: 80%;float: left;}
  .checkout_inner .col-md-4.col-2.last_amount {
      width: 18% !important;
      float: left !important;
  }
  .total-amount .main-total .col-md-6.col-6 {
      width: 50% !important;
      float: left !important;
  }

  .widget-user-main .card-header .col-sm-8 {
    width: 80%;
    float: left;
  }
  .widget-user-main .card-header .col-sm-4 {
    width: 20%;
    float: left;
  }
  .page-content {
    padding: 15px 5px !important;
  }
  .add_form_sec td input {
    width: 100%;
    max-width: 80px;
  }
  .add_form_sec td:first-child input {
    width: auto;
    max-width: initial;
    margin-left: 10px;
  }
  .add_form_sec table.dataTable > tbody > tr.child span.dtr-title {
    min-width: auto;
    font-size: 12px;
      text-align: left;
  }
}
