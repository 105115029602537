/*
 * DATATABLES RESPONSIVE
 */
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child {
    position: relative;
    cursor: pointer;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    top: 13px;
    left: 6px;
    height: 16px;
    width: 16px;
    display: block;
    position: static;
    color: white;
    border: none;
    -webkit-border-radius: 100px;
            border-radius: 100px;
    text-align: center;
    line-height: 15px;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
    content: '+';
    background-color: #acb7bf;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child.dataTables_empty:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child.dataTables_empty:before {
    display: none;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
    content: '-';
    background-color: #acb7bf;
    line-height: 14px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.child td:before {
    display: none;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
    padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
    top: 5px;
    left: 4px;
    height: 14px;
    width: 14px;
    -webkit-border-radius: 14px;
            border-radius: 14px;
    line-height: 12px;
}

table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
    position: relative;
    cursor: pointer;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
    top: 50%;
    left: 50%;
    height: 16px;
    width: 16px;
    margin-top: -10px;
    margin-left: -10px;
    display: block;
    position: absolute;
    color: white;
    border: none;
    -webkit-border-radius: 100px;
            border-radius: 100px;
    text-align: center;
    line-height: 15px;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
    content: '+';
    background-color: #acb7bf;
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
    content: '-';
    background-color: #acb7bf;
}

table.dataTable > tbody > tr.child {
    padding: 0.5em 1em;
}

table.dataTable > tbody > tr.child:hover {
    background: transparent !important;
}

table.dataTable > tbody > tr.child ul {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

table.dataTable > tbody > tr.child ul li {
    border-bottom: 1px solid #efefef;
    padding: 0.5em 0;
}

table.dataTable > tbody > tr.child ul li:first-child {
    padding-top: 0;
}

table.dataTable > tbody > tr.child ul li:last-child {
    border-bottom: none;
}

table.dataTable > tbody > tr.child span.dtr-title {
    display: inline-block;
    min-width: 75px;
    font-weight: bold;
}

table.dataTable .child li {
    white-space: normal;
}

@media (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_info {
        margin-bottom: 10px;
    }
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    bottom: .7em;
}
